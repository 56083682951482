<template>
  <div>
    <div class="wxPay">
      <!-- logo -->
      <div class="wxPay-header">
        <img class="wxPay-logo" src="~a/wxPay/logo.png" alt="" />
        <div class="splitLine"></div>
        <p class="wxPay-title">我的收银台</p>
      </div>
      <div class="wxPay-main">
        <!-- 支付信息 -->
        <div class="wxPay-info">
          <!-- 左侧 -->
          <div class="wxPay-info-left">
            <!-- 订单 -->
            <div class="wxPay-info-order">
              请您及时付款，以便我们为您尽快处理哦！订单号：{{ orderId }}
            </div>
            <!-- 套餐名称 -->
            <div class="wxPay-info-name">
              {{ orderName }} 收款方：神州龙空间技术（深圳）有限公司
            </div>
          </div>
          <!-- 右侧 -->
          <div class="wxPay-info-right">
            <!-- 金额 -->
            <div class="wxPay-info-price">￥ {{ money }}</div>
          </div>
        </div>
        <!-- 二维码信息 -->
        <div class="wxPay-qrCode">
          <div>
            <p class="wxPay-qrCode-title">微信支付</p>
            <!-- 二维码图片 -->
            <div id="qrcode" class="wxPay-qrCode-img"></div>
            <div class="wxPay-qrCode-tip">
              <p>请使用微信扫一扫 扫描二维码支付</p>
            </div>
          </div>
          <div class="splitLine"></div>
          <div>
            <!-- 操作指引 -->
            <img class="wxPay-qrCode-guide" src="~a/wxPay/img.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WxPay',
  data() {
    return {
      orderId: '',
      orderName: '',
      money: ''
    };
  },
  mounted() {
    const { orderId, orderName, money, codeUrl } = this.$route.query;
    this.orderId = orderId;
    this.orderName = orderName;
    this.money = money;
    this.qrcode = new window.QRCode(document.getElementById('qrcode'), {
      text: codeUrl,
      width: 348,
      height: 348,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: window.QRCode.CorrectLevel.H
    });
  },
  destroyed() {
    this.qrcode.clear();
  }
};
</script>

<style lang="less" scoped>
.wxPay {
  box-sizing: border-box;
  width: 1440px;
  padding-top: 50px;
  margin: 0 auto;
  &-header {
    display: flex;
    align-items: center;
    height: 46px;
    margin-bottom: 16px;
    color: #999999;
    font-size: 20px;
    .splitLine {
      width: 2px;
      height: 39px;
      margin: 0 20px;
      background: #82878a;
    }
  }
  &-logo {
    width: 206px;
    height: 46px;
  }
  &-title {
    align-self: flex-end;
  }
  &-main {
    padding: 36px 80px 36px 26px;
    background: #eff0f1;
  }
  &-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
    &-order {
      margin-bottom: 28px;
      color: #333333;
      font-size: 20px;
      font-weight: bold;
    }
    &-name {
      color: #999;
      font-size: 18px;
    }
    &-price {
      color: #ff0000;
      font-size: 26px;
      font-weight: bold;
    }
  }
  &-qrCode {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 569px;
    margin-left: 55px;
    background-color: #fff;
    .splitLine {
      width: 2px;
      height: 459px;
      margin: 0 140px;
      background: #eeeeee;
    }
    &-title {
      text-align: center;
      color: #333;
      font-size: 28px;
      margin-bottom: 22px;
      font-weight: bold;
    }
    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 356px;
      height: 356px;
      margin: 0 auto 20px auto;
      background: #ffffff;
      border: 2px solid #d2d2d2;
      border-radius: 4px;
    }
    &-tip {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 356px;
      height: 76px;
      padding: 0 60px;
      background: #fc5e62;
      color: #fff;
      font-size: 18px;
      img {
        width: 54px;
        height: 54px;
        margin-right: 36px;
      }
    }
    &-guide {
      width: 303px;
      height: 386px;
    }
  }
}
</style>
